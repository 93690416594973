import React, { useRef, useState, useEffect } from 'react';

import Axios from 'axios';

import Grid from '@mui/material/Grid';
import Item from '@mui/material/Grid';

import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom'
import DownloadIcon from '@mui/icons-material/Download';
import FormHelperText from '@mui/material/FormHelperText';
import { Switch, Autocomplete } from '@mui/material/';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import {
    Box,
    InputLabel,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    FormControl,
    Paper,
    Select,
    Skeleton,
    Stack,
    IconButton,
    Typography,
} from "@mui/material";

// import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
// import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
// import RestartAltIcon from '@mui/icons-material/RestartAlt';
// import ShowChartIcon from '@mui/icons-material/ShowChart';

import gistdaPic from '../imgs/gistda_logo.png';
import kuPic from '../imgs/ku.png';
import mhesiPic from '../imgs/mhesi.png';
import nrctPic from '../imgs/nrct.png';
import pcdPic from '../imgs/pcd.png';
import ccdcPic from '../imgs/ccdc.png';
import andPic from '../imgs/android.png';
import aaplPic from '../imgs/aapl.png';
import PMUBPic from '../imgs/PMUB-01.png';

import CircularProgress from '@mui/material/CircularProgress';


import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { color } from 'highcharts';


const FileDownload = require('js-file-download');


const Download = () => {
    const steps = [
        {
            label: 'เลือกวันที่ที่ต้องการดาวน์โหลดข้อมูล',
            description: ``,
        },
        {
            label: 'เลือกพื้นที่ที่สนใจ',
            description: '',
        },
        {
            label: 'เลือกข้อมูลที่ต้องการดาวน์โหลด',
            description: ``,
        },
    ];

    const [activeStep, setActiveStep] = React.useState(0);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const [windowSize, setWindowSize] = useState([
        window.innerWidth,
        window.innerHeight,
    ]);

    const [admin, setAdmin] = React.useState('');

    const handleSelect = (event) => {
        setisLoading2(true);
        setAdmin(event.target.value);
        setisLoading2(false);
    };

    const handleC1 = (event) => {
        setC1(event.target.checked);
    };
    const handleC2 = (event) => {
        setC2(event.target.checked);
    };
    const handleC3 = (event) => {
        setC3(event.target.checked);
    };

    const handleChangePv = (event) => {
        setPv(event.target.value);
        setApl([]);
        setTbl([]);
        setAp(0);
        setTb(0);
        getApl(event.target.value);
        getTbl(0);
        console.log(pv);
    };

    const handleChangeAp = (event) => {
        setAp(event.target.value);
        setTbl([]);
        setTb(0);
        getTbl(event.target.value);

        console.log(ap);
    };

    const handleChangeTb = (event) => {
        setTb(event.target.value);
        console.log(tb);
    };

    const getPvl = async () => {
        const response = await fetch(
            urlPath + "/rest/getProvName"
        );
        const result = await response.json();

        let d = result.data

        d.unshift({
            "prov_t": "==ทุกจังหวัด==",
            "prov_idn": 0
        });

        setPvl(d);
        setIsLoadingPv(false);
    };

    const getApl = async (id) => {
        const response = await fetch(
            urlPath + "/rest/getAmpName?pv=" + id
        );
        const result = await response.json();

        let d = result.data;

        d.unshift({
            "amphoe_t": "==ทุกอำเภอ==",
            "amphoe_idn": 0
        });

        setApl(d);
        setIsLoadingAp(false);
    };

    const getTbl = async (id) => {
        const response = await fetch(
            urlPath + "/rest/getTamName?ap=" + id
        );
        const result = await response.json();

        let d = result.data;

        d.unshift({
            "tambon_t": "==ทุกตำบล==",
            "tambon_idn": 0
        });

        setTbl(result.data);
        setIsLoadingTb(false);
    };

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: window.screen.width > 700 ? 700 : 350,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };


    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.default,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));

    const onKeyDown = (e) => {
        e.preventDefault();
    };


    const [fetchedData, setFetchedData] = useState([]);
    const [isLoading1, setisLoading1] = useState(false);
    const [isLoading2, setisLoading2] = useState(false);

    const [minMax, setMinMax] = useState([]);

    const [pvl, setPvl] = useState([]);
    const [apl, setApl] = useState([]);
    const [tbl, setTbl] = useState([]);
    const [pv, setPv] = useState(0);
    const [ap, setAp] = useState(0);
    const [tb, setTb] = useState(0);
    const [isLoadingPv, setIsLoadingPv] = useState(true);
    const [isLoadingAp, setIsLoadingAp] = useState(true);
    const [isLoadingTb, setIsLoadingTb] = useState(true);
    const [isLoadingFile, setIsLoadingFile] = useState(false);

    const [sid, setSid] = useState(null);
    const [pid, setPid] = useState(0);
    const [aid, setAid] = useState(null);
    const [tid, setTid] = useState(null);
    const [wid, setWid] = useState(null);

    const [open0, setOpen0] = React.useState(false);
    const [open1, setOpen1] = React.useState(false);
    const [open2, setOpen2] = React.useState(false);
    const [open3, setOpen3] = React.useState(false);
    // const [options, setOptions] = React.useState([]);
    const loadingPv = isLoadingPv && open1 && pvl.length === 0;
    const loadingAp = isLoadingAp && open2 && apl.length === 0;
    const [value0, setValue0] = React.useState([]);
    const [value1, setValue1] = React.useState([]);
    const [value2, setValue2] = React.useState([]);
    const [value3, setValue3] = React.useState([]);

    const [c1, setC1] = React.useState(false);
    const [c2, setC2] = React.useState(false);
    const [c3, setC3] = React.useState(false);

    const [urlPath, setUrlPath] = useState('https://pm25.gistda.or.th');
    // const [urlPath, setUrlPath] = useState('http://127.0.0.1:5000');

    const getData = async (date) => {
        setisLoading1(true);
        // date = date.add(1, 'day')
        // console.log('fetch ' + JSON.stringify(date).split('T')[0].slice(1))
        const response = await fetch(
            urlPath + "/rest/getAvailableCSVInDate?dt=" + JSON.stringify(date).split('T')[0].slice(1)
        );
        const result = await response.json();

        setisLoading1(false);
        setFetchedData(
            result.data.map((time) => ({
                time: time.datetime
            }))
        );
        // console.log(fetchedData);
    };

    const getMinMax = async () => {

        const response = await fetch(
            urlPath + "/rest/getAvailableCSVDownloadDate"
        );
        const result = await response.json();

        setMinMax(result.data);
    };

    const [sDate, setsDate] = React.useState(null);
    const [eDate, seteDate] = React.useState(null);

    const handleLoad = async (date) => {
        // console.log(date)
        Axios({
            url: urlPath + '/rest/getPM25by' + admin + 'AsCSV?dt=' + date,
            method: 'GET',
            responseType: 'blob', // Important
        }).then((response) => {
            FileDownload(response.data, 'PM25_' + admin + "_" + date.replace(" ", "_") + '.csv');
        });

    };

    const handleLoad_24 = async (date1, pv, ap, tb, ad) => {
        console.log(`${pv}  ${ap}  ${tb}  ${ad}`);

        let id = 0;
        switch (ad) {
            case 'Tambon': {
                if (tb == 0) {
                    if (ap == 0) {
                        if (pv == 0) {
                            id = 0;
                        } else {
                            id = pv;
                            console.log(3)
                        }
                    } else {
                        id = ap;
                    }
                } else {
                    id = tb;
                }
            }
                break;
            case 'Amphoe': {
                if (ap == 0) {
                    if (pv == 0) {
                        id = 0;
                    } else {
                        id = pv;
                        console.log(2)

                    }
                } else {
                    id = ap;
                }
            }
                break;
            case 'Province': {
                if (pv == 0) {
                    id = 0;
                } else {
                    id = pv;
                    console.log(1)

                }
            }
                break;
        }
        // console.log(id);
        await setIsLoadingFile(true);
        await Axios({
            url: urlPath + '/rest/getPM25by24hrs' + ad + 'AsCSV?dt1=' + date1 + '&id=' + id,
            method: 'GET',
            responseType: 'blob', // Important
        }).then((response) => {
            FileDownload(response.data, 'PM25_' + ad + "_24hrs_" + date1.replace(" ", "_") + '.csv');
            setIsLoadingFile(false);
        });

    };

    const handleLoad_d = async (date1, pv, ap, tb, ad) => {

        let id = 0;
        switch (ad) {
            case 'Tambon': {
                if (tb === 0) {
                    if (ap === 0) {
                        if (pv === 0) {
                            id = 0;
                        } else {
                            id = pv;
                        }
                    } else {
                        id = ap;
                    }
                } else {
                    id = tb;
                }
            }
            case 'Amphoe': {
                if (ap === 0) {
                    if (pv === 0) {
                        id = 0;
                    } else {
                        id = pv;
                    }
                } else {
                    id = ap;
                }
            }
            case 'Province': {
                if (pv === 0) {
                    id = 0;
                } else {
                    id = pv;
                }
            }
        }
        // console.log(date)
        await setIsLoadingFile(true);
        await Axios({
            url: urlPath + '/rest/getPM25by1d' + ad + 'AsCSV?dt1=' + date1 + '&id=' + id,
            method: 'GET',
            responseType: 'blob', // Important
        }).then((response) => {
            FileDownload(response.data, 'PM25_' + ad + "_1d_" + date1.replace(" ", "_") + '.csv');
            setIsLoadingFile(false);
        });
    };

    const handleLoad_30d = async (date1, pv, ap, tb, ad) => {

        let id = 0;
        switch (ad) {
            case 'Tambon': {
                if (tb === 0) {
                    if (ap === 0) {
                        if (pv === 0) {
                            id = 0;
                        } else {
                            id = pv;
                        }
                    } else {
                        id = ap;
                    }
                } else {
                    id = tb;
                }
            }
            case 'Amphoe': {
                if (ap === 0) {
                    if (pv === 0) {
                        id = 0;
                    } else {
                        id = pv;
                    }
                } else {
                    id = ap;
                }
            }
            case 'Province': {
                if (pv === 0) {
                    id = 0;
                } else {
                    id = pv;
                }
            }
        }
        // console.log(date)
        await setIsLoadingFile(true)
        await Axios({
            url: urlPath + '/rest/getPM25by1m' + ad + 'AsCSV?dt1=' + date1 + '&id=' + id,
            method: 'GET',
            responseType: 'blob', // Important
        }).then((response) => {
            FileDownload(response.data, 'PM25_' + ad + "_1m_" + date1.replace(" ", "_") + '.csv');
            setIsLoadingFile(false);
        });

    };

    const thaiAdmin = () => {
        if (admin === 'Province') {
            return 'จังหวัด';
        } else if (admin === 'Amphoe') {
            return 'อำเภอ';
        } else if (admin === 'Tambon') {
            return 'ตำบล';
        } else {
            return '';
        }
    }

    useEffect(() => {
        getMinMax();
        setisLoading1(true);
        setisLoading2(true);

        const today = new dayjs(new Date());
        const dlevel = 'Province';
        setAdmin(dlevel);
        setsDate(today);
        seteDate(today);
        getData(today);
        setisLoading1(false);
        setisLoading2(false);
        getPvl();
        setPv(0);

        const handleWindowResize = () => {
            setWindowSize([window.innerWidth, window.innerHeight]);
        };
        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);


    return (
        <div className="App" style={{ marginTop: 141 }}>
            {/* <h2>Height: {windowSize[1]}</h2> */}
            <Grid container spacing={{ xs: 1, md: 2 }} >

                <Grid item xs={1} />
                <Grid item xs={10}>
                    <Grid container item xs={12} spacing={2} >
                        <Grid item xs={12} paddingBottom={2}>
                            <Typography variant='h5' fontWeight={'blod'}>ดาวน์โหลดข้อมูลจากระบบฐานข้อมูล PM2.5</Typography>

                        </Grid>

                        <Grid item xs={12} md={4}>
                            <Stepper activeStep={activeStep} orientation="vertical" sx={{
                                ".MuiStepIcon-text": {
                                    fill: "#1976d2",
                                    fontWeight: 500
                                },
                                ".Mui-active .MuiStepIcon-text": {
                                    fill: "#FFFFFF"
                                }
                            }}>

                                {
                                    steps.map((step, index) => (
                                        <Step key={step.label} active={true}>
                                            <StepLabel

                                            // optional={
                                            //     index === steps.length - 1 ? (
                                            //         <Typography variant="caption">Last step</Typography>
                                            //     ) : null
                                            // }
                                            >
                                                {step.label}
                                            </StepLabel>
                                            <StepContent TransitionProps={{ unmountOnExit: false }}>
                                                <Typography>{step.description}</Typography>

                                                {
                                                    index === 0 ?
                                                        <Box sx={{ maxWidth: 200, borderRadius: 10 }}>
                                                            <LocalizationProvider dateAdapter={AdapterDayjs} >
                                                                <DatePicker
                                                                    sx={{ width: 200, bgcolor: '#FFF', WebkitTextFillColor: "#000", borderColor: '#FFF', borderRadius: 10 }}
                                                                    label="เลือกวันที่"
                                                                    minDate={dayjs(minMax.min)}
                                                                    maxDate={dayjs(minMax.max)}
                                                                    value={sDate}
                                                                    onChange={(newValue) => {
                                                                        setsDate(newValue);
                                                                        getData(newValue);
                                                                        // console.log(newValue);
                                                                    }}
                                                                    format="DD-MM-YYYY"
                                                                    // renderInput={(params) => <TextField {...params} />}
                                                                    InputProps={{ readOnly: true, bgcolor: 'theme.palette.default' }}
                                                                />
                                                            </LocalizationProvider>
                                                        </Box>

                                                        : ''
                                                }
                                                {
                                                    index === 1 && !isLoadingPv ?

                                                        <Autocomplete
                                                            value={value1}
                                                            onChange={(event, newValue) => {
                                                                console.log(newValue);
                                                                setValue1(newValue);
                                                                console.log(`${newValue}, ${value2}, ${value3}`)
                                                                setPid(newValue ? newValue.prov_idn : null);

                                                                if (newValue) {
                                                                    getApl(newValue.prov_idn);
                                                                    setWid(newValue.prov_idn);
                                                                    console.log(newValue.prov_idn);
                                                                }
                                                                setValue2([]);
                                                                setValue3([]);
                                                                setAid(null);
                                                                setTid(null);
                                                                setTbl([]);
                                                                if (!newValue) {
                                                                    setApl([])
                                                                    setWid(null)
                                                                }
                                                                setIsLoadingAp(false);
                                                            }}


                                                            sx={{ width: 200, bgcolor: '#FFF', WebkitTextFillColor: "#000", borderColor: '#FFF', borderRadius: 10 }}
                                                            open={open1}
                                                            onOpen={() => {
                                                                setOpen1(true);
                                                            }}
                                                            onClose={() => {
                                                                setOpen1(false);
                                                            }}
                                                            isOptionEqualToValue={(option, value) => option.prov_t === value.prov_t}
                                                            getOptionLabel={(option) => option.prov_t || "เลือกจังหวัด"}
                                                            options={pvl}
                                                            loading={loadingPv}
                                                            noOptionsText={'ไม่มีข้อมูล'}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    label="เลือกจังหวัด"
                                                                    InputProps={{
                                                                        ...params.InputProps,
                                                                        endAdornment: (
                                                                            <React.Fragment>
                                                                                {loadingPv ? <CircularProgress color="inherit" size={20} /> : null}
                                                                                {params.InputProps.endAdornment}
                                                                            </React.Fragment>
                                                                        ),
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                        : index === 1 ? <Typography>กำลังเชื่อมต่อฐานข้อมูล...</Typography>
                                                            : ''
                                                }
                                                {
                                                    index === 1 && !(isLoadingAp || isLoadingPv) ?
                                                        <Box sx={{ minWidth: 120 }}>
                                                            <Autocomplete
                                                                value={value2}
                                                                onChange={(event, newValue) => {

                                                                    console.log(newValue);
                                                                    setValue2(newValue);
                                                                    console.log(`${value1}, ${newValue}, ${value3}`)
                                                                    setAid(newValue ? newValue.amphoe_idn : null);

                                                                    if (newValue) {
                                                                        getTbl(newValue.amphoe_idn);
                                                                        setWid(newValue.amphoe_idn);
                                                                        console.log(newValue.amphoe_idn);
                                                                    }
                                                                    setValue3([]);
                                                                    setTid(null);
                                                                    if (!newValue) {
                                                                        setTbl([])
                                                                        setWid(null)
                                                                    }
                                                                    setIsLoadingTb(false);
                                                                }}
                                                                sx={{ width: 200, bgcolor: '#FFF', WebkitTextFillColor: "#000", borderColor: '#FFF', borderRadius: 10, marginTop: 2 }}
                                                                open={open2}
                                                                onOpen={() => {
                                                                    setOpen2(true);
                                                                }}
                                                                onClose={() => {
                                                                    setOpen2(false);
                                                                }}
                                                                isOptionEqualToValue={(option, value) => option.amphoe_t === value.amphoe_t}
                                                                getOptionLabel={(option) => option.amphoe_t || (pid === '10' ? "เลือกเขต" : "เลือกอำเภอ")}
                                                                options={apl}
                                                                loading={loadingAp}
                                                                noOptionsText={'ไม่มีข้อมูล'}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        label={pid === '10' ? "เลือกเขต" : "เลือกอำเภอ"}
                                                                        InputProps={{
                                                                            ...params.InputProps,
                                                                            endAdornment: (
                                                                                <React.Fragment>
                                                                                    {loadingAp ? <CircularProgress color="inherit" size={20} /> : null}
                                                                                    {params.InputProps.endAdornment}
                                                                                </React.Fragment>
                                                                            ),
                                                                        }}
                                                                    />
                                                                )}
                                                            />
                                                        </Box>
                                                        : ''
                                                }
                                                {
                                                    index === 1 && !(isLoadingTb || isLoadingAp || isLoadingPv) ?
                                                        <Box sx={{ minWidth: 120 }}>
                                                            <Autocomplete
                                                                value={value3}
                                                                onChange={(event, newValue) => {
                                                                    console.log(newValue);
                                                                    setValue3(newValue);
                                                                    console.log(`${value1}, ${value2}, ${newValue?.tambon_idn}`)
                                                                    setTid(newValue ? newValue.tambon_idn : null);

                                                                    if (newValue) {
                                                                        setTid(newValue ? newValue.tambon_idn : null);
                                                                        setWid(newValue.tambon_idn);
                                                                        console.log(newValue.tambon_idn);
                                                                    }

                                                                }}
                                                                sx={{
                                                                    width: 200, bgcolor: '#FFF', WebkitTextFillColor: "#000", borderColor: '#FFF', borderRadius: 10, marginTop: 2
                                                                }}
                                                                open={open3}
                                                                onOpen={() => {
                                                                    setOpen3(true);
                                                                }}
                                                                onClose={() => {
                                                                    setOpen3(false);
                                                                }}
                                                                isOptionEqualToValue={(option, value) => option.tambon_t === value.tambon_t}
                                                                getOptionLabel={(option) => option.tambon_t || (pid === '10' ? "เลือกแขวง" : "เลือกตำบล")}
                                                                options={tbl}
                                                                loading={loadingAp}
                                                                noOptionsText={'ไม่มีข้อมูล'}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        label={pid === '10' ? "เลือกแขวง" : "เลือกตำบล"}
                                                                        InputProps={{
                                                                            ...params.InputProps,
                                                                            endAdornment: (
                                                                                <React.Fragment>
                                                                                    {loadingAp ? <CircularProgress color="inherit" size={20} /> : null}
                                                                                    {params.InputProps.endAdornment}
                                                                                </React.Fragment>
                                                                            ),
                                                                        }}
                                                                    />
                                                                )}
                                                            />

                                                        </Box>
                                                        : ''
                                                }
                                                {
                                                    index === 2 ?
                                                        <FormGroup sx={{
                                                            ".MuiSvgIcon-root": {
                                                                // borderRadius: "50%",
                                                                border: "1px solid #1976d2"
                                                            },
                                                        }}>
                                                            <FormControlLabel control={<Checkbox checked={c1} onChange={handleC1} />} label="เฉลี่ย 24 ชั่วโมงต่อเนื่อง" />
                                                            <FormControlLabel control={<Checkbox checked={c2} onChange={handleC2} />} label="รายวัน (1 วัน)" />
                                                            <FormControlLabel control={<Checkbox checked={c3} onChange={handleC3} />} label="รายวัน (1 เดือน)" />
                                                        </FormGroup>
                                                        : ''
                                                }

                                            </StepContent>
                                        </Step>
                                    ))}
                            </Stepper>
                            <Box padding={2}>
                                {/* <Button variant='contained'>ตรวจสอบข้อมูล</Button> */}
                            </Box>
                        </Grid>


                        <Grid item xs={12} md={8}>
                            <TableContainer component={Paper}>
                                <Table size="small" aria-label="ccg sticky table" stickyHeader
                                    sx={{ bgcolor: "background.default" }}>
                                    <TableHead>
                                        {isLoading2 ?
                                            <TableRow>
                                                <TableCell>
                                                    <Skeleton animation="wave" />
                                                </TableCell>
                                                <TableCell>
                                                    <Skeleton animation="wave" />
                                                </TableCell>
                                            </TableRow>
                                            :
                                            <TableRow>
                                                <TableCell align="left" style={{ width: '30%' }}> <div>ข้อมูล</div> </TableCell>
                                                <TableCell align="center" style={{ width: '20%' }}> <div>วันที่</div> </TableCell>
                                                <TableCell align="center" style={{ width: '30%' }}> <div>ชนิด</div> </TableCell>
                                                <TableCell align="center" style={{ width: '20%' }}> <div>สถานะ</div> </TableCell>
                                            </TableRow>
                                        }
                                    </TableHead>
                                    <TableBody>
                                        {c1 ?
                                            <StyledTableRow >
                                                <TableCell align="left"> {!pid && !aid && !tid ? 'ทั้งประเทศ' : ''} {value1?.prov_t} {value2?.amphoe_t} {value3?.tambon_t} </TableCell>
                                                <TableCell align="center"> {sDate?.toISOString().split('T')[0]} </TableCell>
                                                <TableCell align="center">เฉลี่ย 24 ชั่วโมงต่อเนื่อง (1 วัน)</TableCell>
                                                <TableCell align="center">พร้อมดาวน์โหลด</TableCell>
                                            </StyledTableRow > : ''
                                        }
                                        {c2 ?
                                            <StyledTableRow >
                                                <TableCell align="left"> {!pid && !aid && !tid ? 'ทั้งประเทศ' : ''} {value1?.prov_t} {value2?.amphoe_t} {value3?.tambon_t} </TableCell>
                                                <TableCell align="center"> {sDate?.toISOString().split('T')[0]} </TableCell>
                                                <TableCell align="center">รายวัน (1 วัน)</TableCell>
                                                <TableCell align="center">พร้อมดาวน์โหลด</TableCell>
                                            </StyledTableRow > : ''
                                        }
                                        {c3 ?
                                            <StyledTableRow >
                                                <TableCell align="left"> {!pid && !aid && !tid ? 'ทั้งประเทศ' : ''} {value1?.prov_t} {value2?.amphoe_t} {value3?.tambon_t} </TableCell>
                                                <TableCell align="center"> {sDate?.toISOString().split('T')[0]} </TableCell>
                                                <TableCell align="center">รายวัน (1 เดือน)</TableCell>
                                                <TableCell align="center">พร้อมดาวน์โหลด</TableCell>
                                            </StyledTableRow > : ''
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>

                            <Box padding={2}>
                                <Button disabled={isLoadingFile} variant='contained'
                                    onClick={() => {
                                        let lv = 'Tambon';
                                        if (tid == null) { lv = 'Amphoe' }
                                        if (aid == null) { lv = 'Province' }
                                        if (c1) { handleLoad_24(sDate.toISOString().split('T')[0], pid, aid, tid, lv) };
                                        if (c2) { handleLoad_d(sDate.toISOString().split('T')[0], pid, aid, tid, lv) };
                                        if (c3) { handleLoad_30d(sDate.toISOString().split('T')[0], pid, aid, tid, lv) };
                                    }}>{isLoadingFile ? "กำลังดาวน์โหลดข้อมูล..." : "ดาวน์โหลดข้อมูล"}</Button>
                            </Box>
                        </Grid>

                    </Grid>

                    {/* <TableBody>
                        <StyledTableRow >
                            <TableCell align="left"> {'รายจังหวัด'}  </TableCell>
                            <TableCell align="center">
                                <IconButton disabled={isLoadingFile} onClick={() => handleLoad_24(sDate.toISOString().split('T')[0], pid, aid, tid, 'Province')} variant="contained"
                                    aria-label="เฉลี่ยรายจังหวัดทุกชั่วโมงในหนึ่งวัน">
                                    {isLoadingFile ? <CircularProgress size="24px" /> : <DownloadIcon aria-label="เฉลี่ยรายจังหวัดทุกชั่วโมงในหนึ่งวัน" />}
                                </IconButton>
                            </TableCell>
                            <TableCell align="center">
                                <IconButton disabled={isLoadingFile} onClick={() => handleLoad_d(sDate.toISOString().split('T')[0], pid, aid, tid, 'Province')} variant="contained"
                                    aria-label="เฉลี่ยรายอำเภอทุกชั่วโมงในหนึ่งวัน">
                                    {isLoadingFile ? <CircularProgress size="24px" /> : <DownloadIcon aria-label="เฉลี่ยรายอำเภอทุกชั่วโมงในหนึ่งวัน" />}
                                </IconButton>
                            </TableCell>
                            <TableCell align="center">
                                <IconButton disabled={isLoadingFile} onClick={() => handleLoad_30d(sDate.toISOString().split('T')[0], pid, aid, tid, 'Province')} variant="contained"
                                    aria-label="เฉลี่ยรายตำบลทุกชั่วโมงในหนึ่งวัน">
                                    {isLoadingFile ? <CircularProgress size="24px" /> : <DownloadIcon aria-label="เฉลี่ยรายตำบลทุกชั่วโมงในหนึ่งวัน" />}
                                </IconButton>
                            </TableCell>
                        </StyledTableRow >
                        <StyledTableRow >
                            <TableCell align="left"> {'รายอำเภอ'}  </TableCell>
                            <TableCell align="center">
                                <IconButton disabled={isLoadingFile} onClick={() => handleLoad_24(sDate.toISOString().split('T')[0], pid, aid, tid, 'Amphoe')} variant="contained"
                                    aria-label="เฉลี่ยรายจังหวัดในหนึ่งวัน">
                                    {isLoadingFile ? <CircularProgress size="24px" /> : <DownloadIcon aria-label="เฉลี่ยรายจังหวัดในหนึ่งวัน" />}
                                </IconButton>
                            </TableCell>
                            <TableCell align="center">
                                <IconButton disabled={isLoadingFile} onClick={() => handleLoad_d(sDate.toISOString().split('T')[0], pid, aid, tid, 'Amphoe')} variant="contained"
                                    aria-label="เฉลี่ยรายอำเภอในหนึ่งวัน">
                                    {isLoadingFile ? <CircularProgress size="24px" /> : <DownloadIcon aria-label="เฉลี่ยรายอำเภอในหนึ่งวัน" />}
                                </IconButton>
                            </TableCell>
                            <TableCell align="center">
                                <IconButton disabled={isLoadingFile} onClick={() => handleLoad_30d(sDate.toISOString().split('T')[0], pid, aid, tid, 'Amphoe')} variant="contained"
                                    aria-label="เฉลี่ยรายตำบลในหนึ่งวัน">
                                    {isLoadingFile ? <CircularProgress size="24px" /> : <DownloadIcon aria-label="เฉลี่ยรายตำบลในหนึ่งวัน" />}
                                </IconButton>
                            </TableCell>
                        </StyledTableRow >
                        <StyledTableRow >
                            <TableCell align="left"> {'รายตำบล'}  </TableCell>
                            <TableCell align="center">
                                <IconButton disabled={isLoadingFile} onClick={() => handleLoad_24(sDate.toISOString().split('T')[0], pid, aid, tid, 'Tambon')} variant="contained"
                                    aria-label="เฉลี่ยรายจังหวัดทุกวันย้อนหลังหนึ่งเดือน">
                                    {isLoadingFile ? <CircularProgress size="24px" /> : <DownloadIcon aria-label="เฉลี่ยรายจังหวัดทุกวันย้อนหลังหนึ่งเดือน" />}
                                </IconButton>
                            </TableCell>
                            <TableCell align="center">
                                <IconButton disabled={isLoadingFile} onClick={() => handleLoad_d(sDate.toISOString().split('T')[0], pid, aid, tid, 'Tambon')} variant="contained"
                                    aria-label="เฉลี่ยรายจังหวัดทุกวันย้อนหลังหนึ่งเดือน">
                                    {isLoadingFile ? <CircularProgress size="24px" /> : <DownloadIcon aria-label="เฉลี่ยรายจังหวัดทุกวันย้อนหลังหนึ่งเดือน" />}
                                </IconButton>
                            </TableCell>
                            <TableCell align="center">
                                <IconButton disabled={isLoadingFile} onClick={() => handleLoad_30d(sDate.toISOString().split('T')[0], pid, aid, tid, 'Tambon')} variant="contained"
                                    aria-label="เฉลี่ยรายจังหวัดทุกวันย้อนหลังหนึ่งเดือน">
                                    {isLoadingFile ? <CircularProgress size="24px" /> : <DownloadIcon aria-label="เฉลี่ยรายจังหวัดทุกวันย้อนหลังหนึ่งเดือน" />}
                                </IconButton>
                            </TableCell>
                        </StyledTableRow >
                    </TableBody> */}


                    <Grid item xs={12} md={12} >


                        {/* <TableContainer component={Paper} style={{ width: "100%" }}>
                            <Table size="small" aria-label="ccg sticky table" stickyHeader>
                                <TableHead>
                                    {isLoading2 ?
                                        <TableRow>
                                            <TableCell>
                                                <Skeleton animation="wave" />
                                            </TableCell>
                                            <TableCell>
                                                <Skeleton animation="wave" />
                                            </TableCell>
                                        </TableRow>
                                        :
                                        <TableRow>
                                            <TableCell align="left" style={{ width: '70%' }}> <div>ข้อมูลระดับ{thaiAdmin()} ณ เวลา</div> </TableCell>
                                            <TableCell align="center" style={{ width: '10%' }}> <div>ทั่วประเทศ ณ ชั่วโมง</div> </TableCell>
                                            <TableCell align="center" style={{ width: '10%' }}> <div>เฉลี่ย 24 ชั่วโมงต่อเนื่อง</div> </TableCell>
                                            <TableCell align="center" style={{ width: '10%' }}> <div>รายวัน</div> </TableCell>
                                        </TableRow>
                                    }
                                </TableHead>

                                {(new Date(sDate)).toISOString().split('T')[0] === (new Date(eDate)).toISOString().split('T')[0] ?
                                    <TableBody>
                                        {(isLoading1 || isLoading2) || fetchedData.length === 0 ?
                                            <TableRow>
                                                <TableCell>
                                                    <Skeleton animation="wave" />
                                                </TableCell>
                                                <TableCell>
                                                    <Skeleton animation="wave" />
                                                </TableCell>
                                                <TableCell>
                                                    <Skeleton animation="wave" />
                                                </TableCell>
                                                <TableCell>
                                                    <Skeleton animation="wave" />
                                                </TableCell>
                                            </TableRow>
                                            : fetchedData.map((time, index) => (
                                                <StyledTableRow >
                                                    <TableCell align="left"> {time.time.split(" ").join(' เวลา ') + ' น.'}  </TableCell>
                                                    <TableCell align="center" spacing={1}>
                                                        <IconButton disabled={(isLoading1 || isLoading2)} onClick={() => handleLoad(time.time)} variant="contained">
                                                            <DownloadIcon />
                                                        </IconButton>
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <IconButton disabled={(!pv && admin === 'Province') || (!ap && admin === 'Amphoe') || (!tb && admin === 'Tambon')} onClick={() => handleLoad_24(sDate.toISOString().split('T')[0], eDate.toISOString().split('T')[0], admin === 'Province' ? pv : admin === 'Amphoe' ? ap : admin === 'Tambon' ? tb : '')} variant="contained">
                                                            <DownloadIcon />
                                                        </IconButton>
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <IconButton disabled={(!pv && admin === 'Province') || (!ap && admin === 'Amphoe') || (!tb && admin === 'Tambon')} onClick={() => handleLoad_d(sDate.toISOString().split('T')[0], eDate.toISOString().split('T')[0], admin === 'Province' ? pv : admin === 'Amphoe' ? ap : admin === 'Tambon' ? tb : '')} variant="contained">
                                                            <DownloadIcon />
                                                        </IconButton>
                                                    </TableCell>
                                                </StyledTableRow >
                                            ))
                                        }
                                    </TableBody>
                                    :
                                    <TableBody>
                                        {(isLoading1 || isLoading2) || fetchedData.length === 0 ?
                                            <TableRow>
                                                <TableCell>
                                                    <Skeleton animation="wave" />
                                                </TableCell>
                                                <TableCell>
                                                    <Skeleton animation="wave" />
                                                </TableCell>
                                                <TableCell>
                                                    <Skeleton animation="wave" />
                                                </TableCell>
                                                <TableCell>
                                                    <Skeleton animation="wave" />
                                                </TableCell>
                                            </TableRow>
                                            :
                                            <StyledTableRow >
                                                <TableCell align="left"> {sDate.toISOString().split('T')[0]} ถึง {eDate.toISOString().split('T')[0]}  </TableCell>
                                                <TableCell align="center" spacing={1}>
                                                    <IconButton disabled variant="contained">
                                                        <DownloadIcon />
                                                    </IconButton>
                                                </TableCell>
                                                <TableCell align="center">
                                                    <IconButton disabled={(!pv && admin === 'Province') || (!ap && admin === 'Amphoe') || (!tb && admin === 'Tambon')} onClick={() => handleLoad_24(sDate.toISOString().split('T')[0], eDate.toISOString().split('T')[0], admin === 'Province' ? pv : admin === 'Amphoe' ? ap : admin === 'Tambon' ? tb : '')} variant="contained">
                                                        <DownloadIcon />
                                                    </IconButton>
                                                </TableCell>
                                                <TableCell align="center">
                                                    <IconButton disabled={(!pv && admin === 'Province') || (!ap && admin === 'Amphoe') || (!tb && admin === 'Tambon')} onClick={() => handleLoad_d(sDate.toISOString().split('T')[0], eDate.toISOString().split('T')[0], admin === 'Province' ? pv : admin === 'Amphoe' ? ap : admin === 'Tambon' ? tb : '')} variant="contained">
                                                        <DownloadIcon />
                                                    </IconButton>
                                                </TableCell>
                                            </StyledTableRow >

                                        }
                                    </TableBody>
                                }
                            </Table>
                        </TableContainer> */}

                    </Grid>
                </Grid>

                <Grid item xs={12} sx={{ paddingBottom: "7px" }}>
                </Grid>
            </Grid>


        </div >
    );
}

export default Download;