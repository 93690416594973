import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route }
	from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import Home from './pages';
import Download from './pages/download';
import Contact from './pages/contact';
import Nav from './component/navbar/navbar.js'
import Foot from './component/footer.js'
import { createBrowserHistory } from "history";

import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Button from "@mui/material/Button";

import MUIWrapper from "./MUIWrapper";



const hist = createBrowserHistory();

function App() {
	
	return (
		<Router>
			<MUIWrapper>
				{/* <ThemeProvider theme={light}> */}
				{/* <Button onClick={() => setLight((prev) => !prev)}>Toggle Theme</Button> */}
				<Nav />
				<Routes history={hist}>
					<Route exact path='/' element={<Home />} />
					<Route path='/pm25' element={<Home />} />
					<Route path='/download' element={<Download />} />
					<Route path='/contact' element={<Contact />} />
					<Route path="*" element={<Navigate replace to="/" />} />
				</Routes>
				<Foot />
				{/* </ThemeProvider> */}
			</MUIWrapper>

		</Router>
	);
}

export default App;
