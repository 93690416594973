import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { Divider } from '@mui/material';
import { MapContainer, TileLayer, WMSTileLayer, LayersControl } from 'react-leaflet'
import 'leaflet/dist/leaflet.css';

export default function Contact() {
  return (
    <div className="App" style={{ marginTop: 141 }}>
      <Box sx={{ flexGrow: 1, p: 2 }}> {/* เพิ่ม padding ให้ Box */}
        <Grid container xs={12} spacing={3}>
          <Grid item xs={1} />
          <Grid item container xs={5}>
          <MapContainer center={[13.881, 100.565]} zoom={15} style={{ height: 520, minHeight: "100%", borderRadius: 20 }} id='leaflet-container'>

                                <TileLayer
                                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                />


                            </MapContainer>
          </Grid>
          <Grid item container spacing={2} xs={5}>
            <Grid item xs={12}>
              <Typography variant="h5" fontWeight="bold">
                ติดต่อเรา
              </Typography>
            </Grid>
            <Grid item xs={12} textAlign={'left'}>
              <Typography fontWeight="bold">สำนักงานพัฒนาเทคโนโลยีอวกาศและภูมิสารสนเทศ (องค์การมหาชน) สำนักงานใหญ่</Typography>
              <Typography>ศูนย์ราชการเฉลิมพระเกียรติ 80 พรรษา 5 ธันวาคม 2550</Typography>
              <Typography>เลขที่ 120 อาคารรวมหน่วยราชการ (อาคารรัฐประศาสนภักดี) ชั้น 6 และชั้น 7</Typography>
              <Typography>ถนนแจ้งวัฒนะ แขวงทุ่งสองห้อง เขตหลักสี่ กรุงเทพฯ 10210</Typography>
              <Typography>โทรศัพท์ Call Center 0 2141 4444, 0 2141 4674</Typography>
              <Typography>โทรศัพท์ งานสารบรรณ 0 2141 4466, 0 2141 4468</Typography>
              <Typography>โทรสาร 0 2143 9586</Typography>
              <Typography>E-mail: saraban@gistda.or.th</Typography>
            </Grid>
            
            
            <Grid item xs={12} textAlign={'left'}>
              <Typography fontWeight="bold">สำนักงานพัฒนาเทคโนโลยีอวกาศและภูมิสารสนเทศ (บางเขน)</Typography>
              <Typography>สำนักงานพัฒนาเทคโนโลยีอวกาศและภูมิสารสนเทศ (บางเขน)</Typography>
              <Typography>เลขที่ 196 ถนนพหลโยธิน แขวงลาดยาว เขตจตุจักร กรุงเทพฯ 10900</Typography>
              <Typography>โทรศัพท์ 02 561 4253</Typography>
            </Grid>
          </Grid>
          <Grid item xs={1} />

        </Grid>

      </Box>
    </div>

  );
}