import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import { Select, MenuItem } from '@mui/material';

import { Link } from 'react-router-dom'
import { Grid } from '@mui/material';

import gistdaPic from '../../imgs/gistda_logo.png';
import mhesiPic from '../../imgs/mhesi.png';
import nrctPic from '../../imgs/nrct.png';
import andPic from '../../imgs/android.png';
import aaplPic from '../../imgs/aapl.png';
import PMUBPic from '../../imgs/PMUB-01.png';
import InfoIcon from '@mui/icons-material/Info';
import logoIcon from '../../imgs/logo.png';

import tlight from '../../imgs/tlight.svg';
import tdark from '../../imgs/tdark.svg';
import tyellow from '../../imgs/tyellow.svg';


import { useLocation } from 'react-router-dom'

import { MUIWrapperContext } from "../../MUIWrapper.js";


const drawerWidth = 240;
// const navItems = ['Home', 'About', 'Contact'];
const navItems = [
    { text: 'หน้าแรก', href: '/' },
    { text: 'ดาวน์โหลดข้อมูล', href: '/download' },
    // { text: 'บริการข้อมูล (API)', href: '/api' },
    { text: 'ติดต่อเรา', href: '/contact' },
];

function DrawerAppBar(props) {
    const muiUtils = React.useContext(MUIWrapperContext);

    const currentPage = useLocation();

    const { window } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [themeI, setThemeI] = React.useState(1);

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };

    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
            <Typography variant="h6" sx={{ my: 2 }}>
                ระบบติดตาม PM2.5
            </Typography>
            <Divider />
            <List>
                {navItems.map((item) => (
                    <ListItem key={item.text} disablePadding>
                        <Link to={item.href} style={{ textDecoration: 'none' }}>
                            <ListItemButton sx={{ textAlign: 'center', width: '100%' }}>
                                {/* <ListItemText primary={item.text} /> */}
                                <ListItemText primary={<Typography variant="body2" fontWeight={currentPage.pathname === item.href ? '300' : 'blod'} >{item.text}</Typography>} />
                            </ListItemButton>
                        </Link>

                    </ListItem>
                ))}
            </List>
        </Box>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar component="nav">
                {/* <Toolbar>
                    <IconButton
                        color="#FFFFFF"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography
                        variant="h6"
                        component="div"
                        sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
                    >
                        ระบบติดตาม PM2.5
                    </Typography>
                    <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                        {navItems.map((item) => (
                            <Link to={item.href} style={{ textDecoration: 'none' }}>
                                <Button key={item.text} sx={{ color: '#fff' }}>
                                    {item.text}
                                </Button>
                            </Link>
                        ))}
                    </Box>
                </Toolbar> */}
                <Toolbar sx={{ bgcolor: "#FFFFFF" }}>
                    <Grid container>
                        <Grid item xs={1} />

                        <Grid item xs={6} alignContent={'center'}>
                            <IconButton
                                color="#303C46"
                                aria-label="open drawer"
                                edge="start"
                                onClick={handleDrawerToggle}
                                sx={{ mr: 2, display: { sm: 'none' } }}
                            >
                                <MenuIcon />
                            </IconButton>

                            <Grid container direction='row' spacing={2} sx={{
                                justifyContent: "flex-start",
                                alignItems: "center",
                            }}>

                                <Grid item sx={{ display: { xs: 'block', sm: 'none', md: 'block' } }}>
                                    <img src={logoIcon} alt="logo" aria-label='logo'></img>
                                </Grid>

                                <Grid item>
                                    <Typography
                                        variant="h6"
                                        component="div"
                                        color='#303C46'
                                        sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
                                    >
                                        ระบบติดตาม PM2.5
                                    </Typography>
                                    <Typography
                                        variant="body"
                                        component="div"
                                        color='#303C46'
                                        sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
                                        alignItems={'center'}
                                    >

                                    {/* <a lang='th' href="https://www.mhesi.go.th/" target="_blank" aria-label="เว็บไซต์กระทรวง อว."> */}
                                    <img lang='th' src={mhesiPic} height={36} alt="กระทรวงการอุดมศึกษา วิทยาศาสตร์ วิจัยและนวัตกรรม (อว.)" aria-label="กระทรวงการอุดมศึกษา วิทยาศาสตร์ วิจัยและนวัตกรรม (อว.)"></img>
                                    {/* </a> */}
                                    {/* <a lang='th' href="https://www.gistda.or.th/" target="_blank" aria-label="เว็บไซต์ GISTDA"> */}
                                    <img lang='th' src={gistdaPic} height={36} alt="สำนักงานพัฒนาเทคโนโลยีอวกาศและภูมิสารสนเทศ (องค์การมหาชน) :GISTDA" aria-label="สำนักงานพัฒนาเทคโนโลยีอวกาศและภูมิสารสนเทศ (องค์การมหาชน) :GISTDA"></img>
                                    {/* </a> */}
                                    {/* <a lang='th' href="https://www.nrct.go.th/" target="_blank" aria-label="เว็บไซต์ วช."> */}
                                    <img lang='th' src={nrctPic} height={36} alt="สำนักงานการวิจัยแห่งชาติ (วช.)" aria-label="สำนักงานการวิจัยแห่งชาติ (วช.)"></img>
                                    {/* </a> */}
                                    {/* <a lang='th' href="https://pmu-hr.or.th/" target="_blank" aria-label="เว็บไซต์ บคพ."> */}
                                    <img lang='th' src={PMUBPic} height={36} alt="หน่วยบริหารและจัดการทุนด้านการพัฒนากำลังคนและทุนด้านการพัฒนาสถาบันอุดมศึกษาการวิจัยและการสร้างนวัตกรรม (บพค.)" aria-label="หน่วยบริหารและจัดการทุนด้านการพัฒนากำลังคนและทุนด้านการพัฒนาสถาบันอุดมศึกษาการวิจัยและการสร้างนวัตกรรม (บพค.)"></img>
                                    {/* </a> */}
                                    </Typography>
                                </Grid>
                            </Grid>


                        </Grid>
                        <Grid item xs={4}>
                            <Box height={50} color={'#303C46'} sx={{ display: { xs: 'none', sm: 'block' } }} bgcolor={'#F0F5FA'} alignContent={'center'} alignItems={'center'}>
                                <Box sx={{ display: 'flex', alignItems: 'center' }} >
                                    {/* <Box sx={{ mx: 2 }}>ขนาดตัวอักษร</Box>
                                    <Button variant="text" style={{ maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px', backgroundColor: "#FFF", }}>-</Button>
                                    <Button variant="text" style={{ maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px', backgroundColor: "#FFF", }}>ก</Button>
                                    <Button variant="text" style={{ maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px', backgroundColor: "#FFF", }}>+</Button> */}

                                    <Box sx={{ mx: 2 }}>
                                        <Typography fontSize={'10'}>สีตัวอักษร</Typography>
                                    </Box>
                                    <Button lang='th' aria-label='เปลี่ยนธีมหน้าเว็บเป็นธีมสว่าง' onClick={() => muiUtils.toggleColorMode(0)}><img lang='th' src={tlight} alt='ธีมสว่าง' aria-label='ธีมสว่าง' /></Button>
                                    <Button lang='th' aria-label='เปลี่ยนธีมหน้าเว็บเป็นธีมมืด' onClick={() => muiUtils.toggleColorMode(1)}><img lang='th' src={tdark} alt='ธีมมืด' aria-label='ธีมมืด' /></Button>
                                    <Button lang='th' aria-label='เปลี่ยนธีมหน้าเว็บเป็นธีมเหลือง' onClick={() => muiUtils.toggleColorMode(2)}><img lang='th' src={tyellow} alt='ธีมเหลือง' aria-label='ธีมเหลือง' /></Button>

                                    {/* <Box sx={{ mx: 2 }}>ภาษา</Box>
                                    <Button variant="text" style={{ maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px', backgroundColor: "#FFF", }}>TH</Button>
                                    <Button variant="text" style={{ maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px', backgroundColor: "#FFF", }}>EN</Button> */}
                                </Box>
                            </Box>
                            <Box height={50} sx={{ display: { xs: 'none', sm: 'block' } }} alignContent={'center'} alignItems={'center'}>
                                {navItems.map((item) => (
                                    <Link to={item.href} style={{ textDecoration: 'none' }}>
                                        <Button key={item.text} sx={{ color: '#303C46' }}>
                                            <Typography fontSize={14} fontWeight={currentPage.pathname === item.href ? '700' : '400'}>{item.text}</Typography>
                                        </Button>
                                    </Link>
                                ))}
                            </Box>
                        </Grid>
                        <Grid item xs={1} />
                    </Grid>


                </Toolbar>
            </AppBar>

            <Box component="nav">
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    {drawer}
                </Drawer>
            </Box>

        </Box>
    );
}

DrawerAppBar.propTypes = {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};

export default DrawerAppBar;