import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import { Divider, Typography } from '@mui/material';
import Gistda from '../imgs/gistda_logo.png';
import FB from '../imgs/Facebook.png';
import X from '../imgs/TwitterX.png';
import IG from '../imgs/Instagram.png';
import YT from '../imgs/YouTube.png';


export default function Footer() {
    return (
        <Box >
            <Grid container xs={12}>
                <Grid item xs={1} />
                <Grid item container xs={10}>
                    <Divider sx={{
                        padding: 1,
                        height: 2, width: '100%'
                    }} />
                    <Grid item xs={12} paddingTop={1}>
                        <img src={Gistda} height='36px' alt='GISTDA' />
                    </Grid>

                    <Grid item xs={6}>
                        <Grid xs={6}>
                            <Typography fontSize={12}>
                                <b>ที่อยู่:</b> สำนักงานพัฒนาเทคโนโลยีอวกาศและภูมิสารสนเทศ (องค์การมหาชน)
                                สำนักงานใหญ่ (ศูนย์ราชการเฉลิมพระเกียรติ 80 พรรษา 5 ธันวาคม 2550)
                                เลขที่ 120 อาคารรวมหน่วยราชการ (อาคารรัฐประศาสนภักดี) ชั้น 6 และชั้น 7
                                ถนนแจ้งวัฒนะ แขวงทุ่งสองห้อง เขตหลักสี่ กรุงเทพฯ 10210
                            </Typography>
                        </Grid>


                    </Grid>
                    <Grid item xs={5}>
                        <Typography fontSize={16}>
                            Contact Us
                        </Typography>
                        <Typography fontSize={12}>
                            <b>อีเมล:</b> innotech@gistda.or.th

                        </Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <Typography fontSize={16}>
                            Follow Us
                        </Typography>

                        <a href="https://www.facebook.com/gistda" target="_blank"><img src={FB} height={20} alt="Facebook GISTDA"></img></a>
                        <a href="https://x.com/GISTDA" target="_blank"><img src={X} height={20} alt="X GISTDA"></img></a>
                        <a href="https://www.instagram.com/gistda_space/" target="_blank"><img src={IG} height={20} alt="Instagram GISTDA"></img></a>
                        <a href="https://www.youtube.com/c/GISTDAspace" target="_blank"><img src={YT} height={20} alt="Youtube GISTDA"></img></a>
                    </Grid>
                    <Divider sx={{
                        padding: 1,
                        height: 2, width: '100%'
                    }} />
                    <Grid item xs={12} alignItems={'center'} paddingTop={1} paddingBottom={5}>
                        <Typography fontSize={12}>
                            Copyright © 2024 Geo-Informatics and Space Technology Development Agency (Public Organization), สงวนสิทธิ์ทุกประการ
                        </Typography>
                    </Grid>
                </Grid>

                <Grid item xs={1} />
            </Grid>

        </Box>
    );
}