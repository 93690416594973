import { createTheme, ThemeProvider } from "@mui/material";
import { createContext, useMemo, useState } from "react";
import NotoSansThai from './assets/fonts/NotoSansThai.ttf';


const themeLight = createTheme({
    palette: {
        background: {
            default: "#F5F5F7"
        },
        text: {
            primary: "#303C46",
            fontFamily: `sans-serif`,
        }
    },
    typography: { fontFamily: 'Prompt' },
    components: {
        MuiCssBaseline: {
            styleOverrides: `
			@font-face {
			  font-family: 'Prompt';
			  font-style: normal;
			  font-display: swap;
			  font-weight: 400;
			  src: local('Prompt'), local('Prompt-Regular'), url(${NotoSansThai}) format('ttf');
			  unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
			}
		  `,
        },
    },
});

const themeYellow = createTheme({
    palette: {
        background: {
            default: "#000000"
        },
        text: {
            primary: "#FFD347",
            fontFamily: `sans-serif`,
        }
    },
    typography: { fontFamily: 'Prompt' },
    components: {
        MuiCssBaseline: {
            styleOverrides: `
			@font-face {
			  font-family: 'Prompt';
			  font-style: normal;
			  font-display: swap;
			  font-weight: 400;
			  src: local('Prompt'), local('Prompt-Regular'), url(${NotoSansThai}) format('ttf');
			  unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
			}
		  `,
        },
    },
});

const themeDark = createTheme({
    palette: {
        background: {
            default: "#000000"
        },
        text: {
            primary: "#FFFFFF",
            fontFamily: `sans-serif`,
        }
    },
    typography: { fontFamily: 'Prompt' },
    components: {
        MuiCssBaseline: {
            styleOverrides: `
			@font-face {
			  font-family: 'Prompt';
			  font-style: normal;
			  font-display: swap;
			  font-weight: 400;
			  src: local('Prompt'), local('Prompt-Regular'), url(${NotoSansThai}) format('ttf');
			  unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
			}
		  `,
        },
    },
});

export const MUIWrapperContext = createContext({
    toggleColorMode: (i) => { },
});

export default function MUIWrapper({ children }) {
    const [mode, setMode] = useState(themeLight);
    const [tl, setTl] = useState([themeLight, themeDark, themeYellow]);

    const muiWrapperUtils = useMemo(
        () => ({
            toggleColorMode: (i) => {
                setMode(tl[i]);
                // setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
            },
        }),
        []
    );



    const theme = useMemo(
        () =>
            createTheme({
                palette: {
                    mode,
                },
            }),
        [mode]
    );

    return (
        <MUIWrapperContext.Provider value={muiWrapperUtils}>
            <ThemeProvider theme={mode}>{children}</ThemeProvider>
        </MUIWrapperContext.Provider>
    );
}